body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 80px auto;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(4, 47, 94, 1) 35%, rgba(0, 0, 0, 1) 100%);
  font-family: sans-serif;
  font-size: 16px;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.privat_section{
  border: 1px solid red;
  display: flex;
}

.jumbotron {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  max-width: 1140px;
  margin: auto;
}

.navbar{
  max-width: 1140px;
  margin: auto;
  border-bottom: 5px solid #fff;
}

.mycart{
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 10px auto;
  padding: 30px 10px;
}